import { AnalyticsBrowser } from '@segment/analytics-next'
import Bowser from 'bowser'
import memoize from 'memoize-one'
import { useMemo, useContext } from 'react'

import { analyticsContext } from './AnalyticsProvider'
import { TrackingPayload, TrackingPayloadMap } from './types'

const getBrowserInfo = memoize(Bowser.parse)

export type TrackFn = <N extends keyof TrackingPayloadMap>(
  name: N,
  payload: TrackingPayload<N>,
) => void

/**
 * Factory function to generate the track function.
 */
export function trackFactory(analytics: AnalyticsBrowser): TrackFn {
  if (typeof window === 'undefined') {
    return () => {}
  }

  const browser = getBrowserInfo(window.navigator.userAgent)

  return (name, payload) => {
    const pageInfo = {
      path: location.pathname,
      search: location.search,
      title: document.title,
      url: location.href,
    }

    void analytics.track(
      name,
      {
        ...payload,
        pageInfo,
        // Extra device info not covered under the context
        userDeviceInfo: {
          browserEngine: browser.engine.name,
          type: browser.platform.type,
          viewportHeight: window.innerHeight,
          viewportWidth: window.innerWidth,
        },
      },
      {
        // Device context passed to Segment. These are usually sent
        // automatically but we use "Cloud Mode" so we have to manually
        // add some extra properties. These will then automatically be
        // transformed to "Mixpanel Properties" by Segment.
        // see: https://segment.com/docs/connections/spec/common/#context
        context: {
          device: {
            manufacturer: browser.platform.vendor,
            model: browser.platform.model,
          },
          os: {
            name: browser.os.name,
            version: browser.os.version,
          },
          page: pageInfo,
          screen: {
            height: screen.height,
            width: screen.width,
          },
        },
      },
    )
  }
}

export function useAnalytics(): {
  analytics: AnalyticsBrowser
  track: TrackFn
} {
  const ctx = useContext(analyticsContext)
  if (ctx == null) {
    throw new Error('useAnalytics used outside of AnalyticsProvider')
  }

  const { analytics } = ctx

  const track = useMemo(() => {
    return trackFactory(analytics)
  }, [analytics])

  return { analytics, track }
}
