import clsx from 'clsx'
import React, { ReactNode, InputHTMLAttributes } from 'react'

import s from './TextInput.module.scss'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode
  labelClassName?: string
}

export function TextInput({ label, labelClassName, ...inputProps }: Props) {
  return (
    <label className={clsx(s.label, labelClassName)}>
      {label && <span className={s.labelContent}>{label}</span>}
      <input {...inputProps} className={clsx(s.input, inputProps.className)} />
    </label>
  )
}
