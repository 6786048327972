import clsx from 'clsx'
import React, { ReactNode, InputHTMLAttributes, useRef, useEffect } from 'react'

import s from './Checkbox.module.scss'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean
  label: ReactNode
}

export function Checkbox({
  className,
  indeterminate = false,
  label,
  ...inputProps
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <label className={clsx(s.container, className)}>
      <input
        {...inputProps}
        className={s.checkbox}
        type="checkbox"
        ref={inputRef}
      />
      <span className={s.label}>{label}</span>
    </label>
  )
}
