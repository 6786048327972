import React from 'react'
import { connectHits } from 'react-instantsearch-dom'

import s from './NoResults.module.scss'

export const NoResults = connectHits(({ hits }) => {
  if (hits.length) {
    return null
  }

  return (
    <div className={s.container}>
      <h3 className={s.title}>
        Dang! We haven’t yet reached your location yet.{' '}
      </h3>
      <p>
        We’re currently building out a national database but in the meantime you
        can checkout Victoria and South Australia.
      </p>
      <p>
        Want to suggest an area you’d like us to cover or a business we should
        feature?{' '}
        <a
          href="https://www.thinkenough.com/contact"
          target="_blank"
          rel="noreferrer"
        >
          Contact us here.
        </a>
      </p>
    </div>
  )
})
