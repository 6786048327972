import slugify from 'slugify'

import { CreatePagesQuery } from '../../types/graphql'

export function listingTitle(businessName = '', locationName = '') {
  businessName = businessName.trim()
  locationName = locationName.trim()

  if (businessName === locationName) {
    return locationName
  }

  return `${businessName} - ${locationName}`
}

export function listingSlug(
  node: CreatePagesQuery['allAirtableLocations']['nodes'][0],
) {
  const { data: { business, name: locationName } = {}, recordId } = node
  const title = listingTitle(business?.[0]?.data?.name, locationName)
  const slug = slugify(title, { lower: true, strict: true })
  return `/listing/${recordId}-${slug}/`
}

export function isTruthy<T>(value: T | null | undefined): value is T {
  return Boolean(value)
}

export function safeJsonParse<T>(input: string | undefined, fallback: T): T {
  try {
    return input ? JSON.parse(input) : fallback
  } catch (err) {}
  return fallback
}
