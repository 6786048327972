import { Attachment } from 'airtable/lib/attachment'
import { Node } from 'gatsby'

import {
  AirtableBusinessesData,
  AirtableCategoriesData,
} from '../../types/graphql'

export enum CommandsRecordId {
  GoogleSync = 'rec4c15Tok6R3ddEm',
  Publish = 'recZkauIG0QNnxmUy',
}

export enum CommandStatus {
  Complete = 'Complete',
  Failed = 'Failed',
  Pending = 'Pending',
}

export type CommandsFieldSet = {
  actions: string
  function: string
  id: CommandsRecordId
  name: string
  notes: string
  status: CommandStatus
}

export enum DirectoryStatus {
  InReview = 'In Review',
  New = 'New',
  Published = 'Published',
}

export enum BusinessTrading {
  InStore = 'In-store',
  Online = 'Online',
  PopUp = 'Pop-up',
  ViaDistributors = 'Via distributors',
}

export type OverriddenField =
  | 'address'
  | 'images'
  | 'latitude'
  | 'longitude'
  | 'openingHours'
  | 'phoneNumber'
  | 'website'

export type LocationsFieldSet = {
  address: string
  business: string[]
  businessName: string[]
  category: string[]
  description: string
  facebook: string
  googlePlaceId: string
  googlePlaceIdInvalid: boolean
  googlePlaceName: string
  id: string
  imageAttribution: string
  images: ReadonlyArray<Attachment>
  indigenousPlace: string
  instagram: string
  latitude: number
  longitude: number
  modified: string | number
  name: string
  openingHours: string
  overriddenFields: OverriddenField[]
  phoneNumber: string
  status: DirectoryStatus | ''
  trading: string[]
  twitter: string
  website: string
}

export type BusinessesFieldSet = {
  causes: string[]
  certifications: string[]
  description: string
  framework: string[]
  identifiesAs: string[]
  locations: string[]
  modified: string | number
  name: string
  openingHours: string
  purpose: string
  sustainableDevelopmentGoals: string[]
}

export type CategoriesFieldSet = {
  name: string
  parentCategory: string
  summary: string
}

export type FrameworkFieldSet = {
  annualTurnover: string
  annualTurnoverPublish: string // Starts with "Yes" or "No"
  business: string[]
  businessModelDetails: string // Markdown
  businessName: string
  businessSize: string
  businessSizePublish: string // Starts with "Yes" or "No"
  businessSizeVolunteers: string
  businessSizeVolunteersPublish: string // Starts with "Yes" or "No"
  impactReportsAttachments: ReadonlyArray<Attachment>
  impactReportsText: string // Markdown
  investDetails: string // Markdown
  investInPurpose: string[]
  legalStructure: string[]
  legalStructureDetails: string
  legalStructurePublish: string // Starts with "Yes" or "No"
  operatingSince: number // year
  purpose: string // Markdown
  reinvestment: string[]
  reinvestmentDetails: string // Markdown
  staffBeneficiariesPercent: string // Markdown
}

export enum TableName {
  Businesses = 'Businesses',
  Categories = 'Categories',
  Commands = 'Commands',
  Framework = 'Framework',
  Locations = 'Locations',
}
export type FieldSet = {
  [TableName.Businesses]: Partial<BusinessesFieldSet>
  [TableName.Categories]: Partial<CategoriesFieldSet>
  [TableName.Commands]: Partial<CommandsFieldSet>
  [TableName.Framework]: Partial<FrameworkFieldSet>
  [TableName.Locations]: Partial<LocationsFieldSet>
}

export type AirtableBusinessesNode = Node & {
  data: AirtableBusinessesData
  queryName: TableName.Businesses
  recordId: string
  rowIndex: number
  table: TableName.Businesses
}

export type AirtableCategoryNode = Node & {
  data: AirtableCategoriesData
  queryName: TableName.Categories
  recordId: string
  rowIndex: number
  table: TableName.Categories
}
