import { useStaticQuery, graphql } from 'gatsby'
import React, { ChangeEventHandler, useEffect } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { createConnector } from 'react-instantsearch-dom'
import { DeepRequired } from 'ts-essentials'

import { CategorySelectorQuery } from '../../../../types/graphql'
import { useAnalytics } from '../../analytics/track'
import { Checkbox } from '../../elements/Checkbox'
import { Selector, SelectorTitle, SelectorProps } from '../../elements/Selector'

import s from './CategorySelector.module.scss'

export type CategorySearchState = {
  [parent: string]: {
    [child: string]: true
  }
}

type ExposedProps = {
  onUpdateCount?: (count: number) => void
  selectorProps?: Partial<SelectorProps>
}

type ProvidedProps = ExposedProps & {
  categorySearchState: CategorySearchState
}

type ProvidedPropsWithRefine = ProvidedProps & {
  refine: (a: CategorySearchState) => void
}

const getCategoryCount = (categorySearchState: CategorySearchState) =>
  Object.values(categorySearchState).reduce((acc, curr) => {
    const numSubCategories = Object.keys(curr).length
    return acc + numSubCategories + (numSubCategories > 0 ? 0 : 1)
  }, 0)

const getFlatCategories = (categorySearchState: CategorySearchState) => {
  const result: string[] = []

  for (const [parent, children] of Object.entries(categorySearchState)) {
    const hasChildren = Object.keys(children).length > 0

    if (hasChildren) {
      for (const child of Object.keys(children)) {
        result.push(`${parent} > ${child}`)
      }
    } else {
      result.push(parent)
    }
  }

  return result
}

const connectCategorySelector = createConnector<ProvidedProps, ExposedProps>({
  cleanUp(props, { category, ...nextSearchState }) {
    return nextSearchState
  },
  displayName: 'withCategorySelector',
  getProvidedProps(props, searchState) {
    return { categorySearchState: searchState.category ?? {} }
  },
  getSearchParameters(params, props, { category }) {
    params = params.removeDisjunctiveFacet('category')
    params = params.addDisjunctiveFacet('category')

    for (const facet of getFlatCategories(category ?? {})) {
      params = params.addDisjunctiveFacetRefinement('category', facet)
    }

    return params
  },
  refine(props, searchState, categorySearchState: CategorySearchState) {
    return {
      ...searchState,
      category: categorySearchState,
    }
  },
})

export const CategorySelector = connectCategorySelector(
  ({
    categorySearchState,
    onUpdateCount,
    refine,
    selectorProps,
  }: ProvidedPropsWithRefine) => {
    const { globalContext } = useStaticQuery<
      DeepRequired<CategorySelectorQuery>
    >(graphql`
      query CategorySelector {
        globalContext {
          data {
            categories {
              id
              children
            }
          }
        }
      }
    `)

    const categoryCount = getCategoryCount(categorySearchState)

    useEffect(() => {
      onUpdateCount?.(categoryCount)
    }, [categoryCount, onUpdateCount])

    return (
      <Selector
        label="Category"
        marker={categoryCount || null}
        {...selectorProps}
      >
        <SelectorTitle>Select Categories</SelectorTitle>
        {globalContext?.data?.categories?.map(({ children, id }) => (
          <CategorySelectorItem
            key={id}
            parent={id}
            childCategories={children}
            categorySearchState={categorySearchState}
            refine={refine}
          />
        ))}
      </Selector>
    )
  },
)

type CategorySelectorItemProps = {
  categorySearchState: NonNullable<SearchState['category']>
  childCategories: string[]
  parent: string
  refine: (categorySearchState: CategorySearchState) => void
}
export function CategorySelectorItem({
  categorySearchState,
  childCategories,
  parent,
  refine,
}: CategorySelectorItemProps) {
  const selections = categorySearchState[parent]
  const parentChecked = Boolean(selections)
  const childrenChecked = Object.keys(selections ?? {}).length > 0

  const { track } = useAnalytics()

  const handleChange = (
    parent: string,
    child?: string,
  ): ChangeEventHandler<HTMLInputElement> => {
    return (e) => {
      const selected = e.currentTarget.checked
      const newSearchState = toggleCategory(
        categorySearchState,
        selected,
        parent,
        child,
      )
      // We have to run the refine separately otherwise the state will not
      // update immediately after the checkbox is checked
      setTimeout(() => refine(newSearchState))

      track(
        selected
          ? 'SearchFilters:Category:Select'
          : 'SearchFilters:Category:Unselect',
        {
          allSelected: getFlatCategories(newSearchState),
          numSelected: getCategoryCount(newSearchState),
          target: child ? `${parent} > ${child}` : parent,
        },
      )
    }
  }

  return (
    <div className={s.parentContainer}>
      <Checkbox
        className={s.parent}
        label={parent}
        checked={parentChecked}
        indeterminate={childrenChecked}
        onChange={handleChange(parent)}
      />
      {parentChecked && (
        <div className={s.children}>
          {childCategories?.map((child) => (
            <Checkbox
              key={child}
              label={child}
              checked={Boolean(selections?.[child])}
              onChange={handleChange(parent, child)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

function toggleCategory(
  categorySearchState: CategorySearchState | undefined,
  enabled: boolean,
  parent: string,
  child?: string,
): CategorySearchState {
  const newCategory = {
    ...categorySearchState,
    [parent]: {
      ...categorySearchState?.[parent],
    },
  }
  const parentState = newCategory[parent] ?? {}

  if (enabled) {
    if (child) {
      parentState[child] = true
    }
  } else if (child) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete parentState[child]
  } else {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newCategory[parent]
  }

  return newCategory
}
