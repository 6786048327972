import clsx from 'clsx'
import React, { useRef, useEffect, useState } from 'react'
import { GeoSearch, Control } from 'react-instantsearch-dom-maps'

import { Doc } from '../../../types/algolia'
import { useSearchContext } from '../context'

import { Marker } from './Marker'

import s from './Map.module.scss'

type Props = {
  className?: string
}

export function Map({ className }: Props) {
  const { googleSdk, searchState } = useSearchContext()
  const { address } = searchState?.location ?? {}
  const prevAddressRef = useRef(address)
  const [key, setKey] = useState(1)

  useEffect(() => {
    if (prevAddressRef.current !== address && !address) {
      setKey(key + 1)
    }
    prevAddressRef.current = address
  }, [address, key])

  return (
    <div className={clsx(s.container, className)}>
      {googleSdk && (
        <GeoSearch<Doc>
          google={googleSdk}
          initialZoom={12}
          key={key}
          enableRefine={!searchState?.query}
          enableRefineOnMapMove={false}
          gestureHandling="greedy"
          zoomControlOptions={{
            position: googleSdk.maps.ControlPosition.RIGHT_BOTTOM,
          }}
          styles={[
            { featureType: 'poi', stylers: [{ visibility: 'off' }] },
            {
              elementType: 'geometry',
              featureType: 'poi.park',
              stylers: [{ visibility: ' on' }],
            },
            { featureType: 'transit', stylers: [{ visibility: 'off' }] },
            {
              elementType: 'labels.icon',
              featureType: 'road.highway',
              stylers: [{ visibility: 'off' }],
            },
            {
              elementType: 'labels.icon',
              featureType: 'road.highway',
              stylers: [{ visibility: 'off' }],
            },
            {
              elementType: 'geometry',
              featureType: 'road.highway',
              stylers: [{ lightness: 30 }, { saturation: -10 }],
            },
            {
              elementType: 'labels.icon',
              featureType: 'road.arterial',
              stylers: [{ visibility: 'off' }],
            },
          ]}
        >
          {({ hits }) => (
            <div>
              <Control
                translations={{
                  control: (
                    <span className={s.controlLabel}>
                      Search as I move the map
                    </span>
                  ),
                  redo: <span>Search this area</span>,
                }}
              />
              {hits.map((hit) => (
                <Marker key={hit.objectID} hit={hit} />
              ))}
            </div>
          )}
        </GeoSearch>
      )}
    </div>
  )
}
