import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useEffect } from 'react'
import { DeepRequired } from 'ts-essentials'

import { MoreFiltersSelectorQuery } from '../../../../types/graphql'
import { BusinessTrading as BT } from '../../../types/airtable'
import { SelectorTitle, Selector, SelectorProps } from '../../elements/Selector'
import { useSearchContext } from '../context'

import { createFacetSelector } from './FacetSelector'

import s from './MoreFiltersSelector.module.scss'

const IdentifiesAsSelector = createFacetSelector('identifiesAs')
const CertificationsSelector = createFacetSelector('certifications')
const CausesSelector = createFacetSelector('causes')
const TradingSelector = createFacetSelector('trading')

type Props = {
  onUpdateCount?: (count: number) => void
  selectorProps?: Partial<SelectorProps>
}
export function MoreFiltersSelector({
  selectorProps = {},
  onUpdateCount,
}: Props) {
  const { searchState } = useSearchContext()
  const { causes, certifications, identifiesAs } = useStaticQuery<
    DeepRequired<MoreFiltersSelectorQuery>
  >(graphql`
    query MoreFiltersSelector {
      globalContext {
        data {
          causes
          certifications
          identifiesAs
        }
      }
    }
  `).globalContext.data

  const numFilters =
    Object.keys(searchState?.identifiesAs ?? {}).length +
    Object.keys(searchState?.certifications ?? {}).length +
    Object.keys(searchState?.causes ?? {}).length +
    Object.keys(searchState?.trading ?? {}).length

  useEffect(() => {
    onUpdateCount?.(numFilters)
  }, [numFilters, onUpdateCount])

  return (
    <Selector
      label="More Filters"
      {...selectorProps}
      classNames={{
        ...selectorProps.classNames,
        content: clsx(s.selectorContent, selectorProps.classNames?.content),
      }}
      marker={numFilters || null}
    >
      <SelectorTitle>More Filters</SelectorTitle>
      <h4 className={s.facetTitle}>Identifies As</h4>
      <IdentifiesAsSelector
        className={s.facetContainer}
        facets={identifiesAs}
      />
      <h4 className={s.facetTitle}>Certifications</h4>
      <CertificationsSelector
        className={s.facetContainer}
        facets={certifications}
      />
      <h4 className={s.facetTitle}>Supporting / Causes</h4>
      <CausesSelector className={s.facetContainer} facets={causes} />
      <h4 className={s.facetTitle}>Operating / Available</h4>
      <TradingSelector
        className={s.facetContainer}
        facets={[BT.Online, BT.InStore, BT.PopUp]}
        additionalFacetMap={{
          [BT.InStore]: BT.ViaDistributors,
          [BT.Online]: BT.ViaDistributors,
        }}
      />
    </Selector>
  )
}
