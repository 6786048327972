import { SearchState } from 'react-instantsearch-core'

export const getSearchUri = (
  searchState: SearchState = {},
  search?: string,
) => {
  const query = new URLSearchParams(search)
  query.set('query', JSON.stringify(searchState))
  return `/search/?${query.toString()}`
}
