import '@fontsource/poppins/400.css'
import '@fontsource/poppins/400-italic.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import 'normalize.css'
import './src/styles/theme.scss'
import './src/styles/global.scss'

import { GatsbyBrowser } from 'gatsby'

import wrapRoot from './src/components/search/context'
import wrapPage from './src/components/search/SearchApp'

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps,
}) => {
  return !routerProps.location.state?.skipScroll
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = wrapRoot

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = wrapPage

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (process.env.GATSBY_PARENT_ORIGIN) {
    window.parent.postMessage(
      {
        payload: { uri: location.href },
        type: 'URI_UPDATE',
      },
      process.env.GATSBY_PARENT_ORIGIN,
    )
  }
}
