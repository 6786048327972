import clsx from 'clsx'
import React, { ReactNode, useState, ComponentProps } from 'react'

import s from './Selector.module.scss'

export type SelectorClassNames = {
  button?: string
  container?: string
  content?: string
  contentInner?: string
}

export type SelectorProps = {
  children: ReactNode
  classNames?: SelectorClassNames
  defaultIsOpen?: boolean
  label: ReactNode
  marker?: ReactNode
  onOpenChange?: (isOpen: boolean) => void
}

export function Selector({
  children,
  classNames = {},
  defaultIsOpen = false,
  label,
  marker,
  onOpenChange,
}: SelectorProps) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  return (
    <div
      className={clsx(s.container, isOpen && s.isOpen, classNames.container)}
    >
      <SelectorButton
        className={classNames.button}
        marker={marker}
        onClick={() => {
          setIsOpen(!isOpen)
          onOpenChange?.(!isOpen)
        }}
      >
        {label}
      </SelectorButton>
      <div className={clsx(s.content, classNames.content)}>
        <div className={clsx(s.contentInner, classNames.contentInner)}>
          {children}
        </div>
      </div>
    </div>
  )
}

type SelectorButtonProps = ComponentProps<'button'> & {
  children: ReactNode
  marker?: ReactNode
}
export function SelectorButton({
  children,
  className,
  marker,
  ...props
}: SelectorButtonProps) {
  return (
    <button type="button" {...props} className={clsx(s.button, className)}>
      <span className={s.label}>{children}</span>
      <svg
        className={s.chevron}
        width="10"
        height="5"
        viewBox="0 0 10 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.22473 0.292893C1.65913 -0.0976315 2.36344 -0.0976315 2.79784 0.292893L5.34836 2.58579L7.89888 0.292893C8.33329 -0.0976318 9.03759 -0.0976318 9.472 0.292893C9.9064 0.683417 9.9064 1.31658 9.472 1.70711L6.13492 4.70711C5.70052 5.09763 4.99621 5.09763 4.56181 4.70711L1.22473 1.70711C0.790325 1.31658 0.790325 0.683417 1.22473 0.292893Z"
          fill="currentColor"
        />
      </svg>
      {marker && <span className={s.labelSuperscript}>{marker}</span>}
    </button>
  )
}

type SelectorTitleProps = {
  children: ReactNode
}
export function SelectorTitle({ children }: SelectorTitleProps) {
  return <h3 className={s.selectorTitle}>{children}</h3>
}
