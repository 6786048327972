import useIntersectionObserver from '@react-hook/intersection-observer'
import clsx from 'clsx'
import { Link } from 'gatsby'
import React, { useEffect, useRef, RefObject } from 'react'

import { useDebounce } from '../../../hooks'
import { BusinessTrading } from '../../../types/airtable'
import { DirectoryHit } from '../../../types/algolia'
import { isOnlineOnly } from '../../../utils/hits'
import { useAnalytics } from '../../analytics/track'
import { Pill } from '../../elements/Pill'
import { useSearchContext } from '../context'

import s from './HitSummary.module.scss'

const maxWindowWidth = parseInt(s.maxWindowWidth ?? '0')

type Props = {
  hit: DirectoryHit
  scrollRootRef: RefObject<HTMLElement>
}

export function HitSummary({ hit, scrollRootRef }: Props) {
  const {
    scrollToSelected,
    selectedHit,
    setHoveredHit,
    setScrollToSelected,
    setSelectedHit,
  } = useSearchContext()

  const { track } = useAnalytics()

  const scrollTargetRef = useRef<HTMLElement>(null)
  const linkRef = useRef<HTMLAnchorElement>(null)

  const active = selectedHit?.objectID === hit.objectID

  const { isIntersecting } = useIntersectionObserver(linkRef, {
    initialIsIntersecting: active,
    root: scrollRootRef.current,
    threshold: 0.5,
  })
  const isIntersectingDebounced = useDebounce(isIntersecting, 300)

  // console.log('KEEGAN: isIntersecting', isIntersecting, hit.title)

  useEffect(() => {
    if (isIntersectingDebounced && window.innerWidth <= maxWindowWidth) {
      setSelectedHit(hit)
    }
  }, [hit, isIntersectingDebounced, scrollRootRef, setSelectedHit])

  useEffect(() => {
    if (active && !isIntersectingDebounced && scrollToSelected) {
      setScrollToSelected(false)
      scrollTargetRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      })
    }
  }, [active, isIntersectingDebounced, scrollToSelected, setScrollToSelected])

  return (
    <Link
      innerRef={linkRef}
      to={hit.slug}
      className={clsx(s.container, active && s.active)}
      onMouseEnter={() => !isOnlineOnly(hit) && setHoveredHit(hit)}
      onMouseLeave={() => !isOnlineOnly(hit) && setHoveredHit(undefined)}
      onClick={() => {
        track('SearchResults:Click:Result', {
          eventType: 'click',
          index: process.env.GATSBY_ALGOLIA_INDEX_NAME ?? '',
          objectID: hit.objectID,
          position: hit.__position,
          queryID: hit.__queryID,
        })
      }}
    >
      <i className={s.scrollTarget} ref={scrollTargetRef} />
      <div className={s.imageContainer}>
        {hit.image && <img className={s.image} src={hit.image?.url} alt="" />}
      </div>
      <div className={s.contentContainer}>
        <h2 className={s.title}>{hit.title}</h2>
        {hit.trading.includes(BusinessTrading.InStore) ? (
          <div className={s.addressContainer}>
            <p className={s.address}>{hit.address}</p>
            {hit.indigenousPlace && (
              <p className={s.address}>, {hit.indigenousPlace}</p>
            )}
          </div>
        ) : (
          <p className={s.onlineBusiness}>
            {hit.trading.includes(BusinessTrading.PopUp) &&
            !hit.trading.includes(BusinessTrading.Online)
              ? 'Pop-up'
              : 'Online'}{' '}
            business
          </p>
        )}
        <ul className={s.categoryContainer}>
          {hit.category
            .filter((c) => !c.includes('>'))
            .map((c) => (
              <Pill className={s.categoryPill} key={c}>
                {c}
              </Pill>
            ))}
        </ul>
        <p className={s.description}>{hit.description}</p>
        <p className={s.causes}>
          <span className={s.causesTitle}>Supporting/Causes:</span>{' '}
          {hit.causes.join(', ')}
        </p>
      </div>
    </Link>
  )
}
