import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby'
import React, {
  useMemo,
  useState,
  ReactElement,
  Dispatch,
  SetStateAction,
  useContext,
  createContext,
} from 'react'
import { SearchState } from 'react-instantsearch-core'
import { Google } from 'react-instantsearch-dom-maps'

import { DirectoryHit } from '../../types/algolia'
import { AnalyticsProvider } from '../analytics/AnalyticsProvider'

export type SearchContext = {
  googleSdk?: Google
  hoveredHit?: DirectoryHit
  scrollToSelected: boolean
  searchState?: SearchState
  selectedHit?: DirectoryHit
  setGoogleSdk: Dispatch<SetStateAction<Google | undefined>>
  setHoveredHit: Dispatch<SetStateAction<DirectoryHit | undefined>>
  setScrollToSelected: Dispatch<SetStateAction<boolean>>
  setSearchState: Dispatch<SetStateAction<SearchState>>
  setSelectedHit: Dispatch<SetStateAction<DirectoryHit | undefined>>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const searchContext = createContext({} as SearchContext)

export function useSearchContext() {
  return useContext(searchContext)
}

export type ProviderProps = {
  children: ReactElement
}
export function Provider({ children }: ProviderProps) {
  const [searchState, setSearchState] = useState<SearchState>()
  const [selectedHit, setSelectedHit] = useState<DirectoryHit>()
  const [hoveredHit, setHoveredHit] = useState<DirectoryHit>()
  const [scrollToSelected, setScrollToSelected] = useState(false)
  const [googleSdk, setGoogleSdk] = useState<Google>()

  const contextValue = useMemo<SearchContext>(
    () => ({
      googleSdk,
      hoveredHit,
      scrollToSelected,
      searchState,
      selectedHit,
      setGoogleSdk,
      setHoveredHit,
      setScrollToSelected,
      setSearchState,
      setSelectedHit,
    }),
    [googleSdk, hoveredHit, scrollToSelected, searchState, selectedHit],
  )

  return (
    <searchContext.Provider value={contextValue}>
      {children}
    </searchContext.Provider>
  )
}

export default function wrap({
  element,
}: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) {
  return (
    <AnalyticsProvider>
      <Provider>{element}</Provider>
    </AnalyticsProvider>
  )
}
