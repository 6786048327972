import 'instantsearch.css/themes/reset.css'

import { SearchOptions } from '@algolia/client-search'
import algoliasearch from 'algoliasearch/lite'
import clsx from 'clsx'
import React, { useEffect, useRef, useMemo } from 'react'
import { InstantSearch, Configure, InfiniteHits } from 'react-instantsearch-dom'
import { GoogleMapsLoader, Google } from 'react-instantsearch-dom-maps'
import { Writable } from 'ts-essentials'

import { SearchStateChangedHandler, DirectoryHit } from '../../types/algolia'

import { useSearchContext } from './context'
import { Map } from './map/Map'
import { HitSummary } from './results/HitSummary'
import { NoResults } from './results/NoResults'
import { StateResults } from './results/StateResults'
import { SearchBar } from './search-bar/SearchBar'

import s from './Search.module.scss'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID ?? '',
  process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? '',
)

type HitWrapperProps = {
  hit: DirectoryHit
}

type Props = {
  listingShowing?: boolean
  onSearchChange: SearchStateChangedHandler
}

export function Search({ listingShowing, onSearchChange }: Props) {
  const { searchState, setSearchState, setSelectedHit } = useSearchContext()
  const { boundingBox, page = 1, query } = searchState ?? {}

  const hitsContainerRef = useRef<HTMLDivElement>(null)
  const prevPageRef = useRef(page)
  const prevBoundingBoxRef = useRef(boundingBox)

  const configureProps: Writable<SearchOptions> = {
    clickAnalytics: true,
    hitsPerPage: 25,
  }

  if (!boundingBox && !query) {
    configureProps.aroundLatLngViaIP = true
    // configureProps.aroundRadius = 200_000
  }

  useEffect(() => {
    if (query && boundingBox) {
      setSearchState((prevState) => ({ ...prevState, boundingBox: undefined }))
    }
  }, [boundingBox, query, setSearchState])

  useEffect(() => {
    if (
      prevPageRef.current > page ||
      prevBoundingBoxRef.current !== boundingBox
    ) {
      setSelectedHit(undefined)

      // we have to wrap this in a timeout and RAF to stop the scroll-snap-type
      // fighting with the scroll
      setTimeout(() => {
        requestAnimationFrame(() => {
          hitsContainerRef.current?.scroll({
            behavior: 'smooth',
            left: 0,
            top: 0,
          })
        })
      }, 50)
    }
    prevPageRef.current = page
    prevBoundingBoxRef.current = boundingBox
  }, [boundingBox, page, setSelectedHit])

  const HitWrapper = useMemo(
    () =>
      ({ hit }: HitWrapperProps) =>
        <HitSummary hit={hit} scrollRootRef={hitsContainerRef} />,
    [],
  )

  return (
    <InstantSearch
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME ?? ''}
      searchClient={searchClient}
      onSearchStateChange={onSearchChange}
      searchState={searchState ?? {}}
      stalledSearchDelay={200}
    >
      <GoogleMapsLoader apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
        {(googleSdk) => <GoogleSdkLoader googleSdk={googleSdk} />}
      </GoogleMapsLoader>
      <Configure {...configureProps} />
      <StateResults>
        {({ searchResults }) => {
          const { nbHits = 0, nbPages = 0, page = 0 } = searchResults ?? {}
          return (
            <div
              className={clsx(
                s.container,
                listingShowing && s.listingShowing,
                page + 1 < nbPages && s.hasMorePages,
                nbHits === 0 && s.noResults,
              )}
            >
              <header className={s.header}>
                <div className={s.titleContainer}>
                  <h1 className={s.title}>
                    Directory <span className={s.beta}>(beta)</span>
                  </h1>
                  {/* <aside>
                <a
                  className={s.poweredBy}
                  href="https://www.thinkenough.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Powered by <span className="_visuallyHidden">Think Enough</span>
                  <img
                    className={s.thinkEnoughLogo}
                    src="/images/think-enough-logo.png"
                    alt=""
                  />
                </a>
              </aside> */}
                </div>
                <SearchBar className={s.searchBar} />
              </header>
              <div className={clsx(s.hitsContainer)} ref={hitsContainerRef}>
                <InfiniteHits
                  hitComponent={HitWrapper}
                  translations={{ loadMore: 'More', loadPrevious: 'Previous' }}
                />
                {searchResults != null && <NoResults />}
              </div>
              <Map className={s.map} />
            </div>
          )
        }}
      </StateResults>
    </InstantSearch>
  )
}

type GoogleSdkLoaderProps = {
  googleSdk: Google
}
function GoogleSdkLoader({ googleSdk }: GoogleSdkLoaderProps) {
  const { googleSdk: ctxGoogleSdk, setGoogleSdk } = useSearchContext()

  useEffect(() => {
    if (!ctxGoogleSdk && googleSdk) {
      setGoogleSdk(googleSdk)
    }
  }, [ctxGoogleSdk, googleSdk, setGoogleSdk])

  return null
}
