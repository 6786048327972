import clsx from 'clsx'
import React, { useState } from 'react'

import { useAnalytics } from '../../analytics/track'
import { Button } from '../../elements/Button'
import { Selector, SelectorClassNames } from '../../elements/Selector'
import { useSearchContext } from '../context'

import { CategorySelector } from './CategorySelector'
import { LocationSelector } from './LocationSelector'
import { MoreFiltersSelector } from './MoreFiltersSelector'
import { SearchBox } from './SearchBox'

import s from './SearchBar.module.scss'

const selectorClassNames: SelectorClassNames = {
  button: s.selectorButton,
  container: s.selectorContent,
  content: s.selectorContent,
  contentInner: s.selectorContent,
}

type Props = {
  className?: string
}

export function SearchBar({ className }: Props) {
  const { setSearchState } = useSearchContext()
  const { track } = useAnalytics()
  const [address, setAddress] = useState('')
  const [categoryCount, setCategoryCount] = useState(0)
  const [moreFiltersCount, setMoreFiltersCount] = useState(0)

  const filtersCount =
    (address ? 1 : 0) + categoryCount + moreFiltersCount || null

  return (
    <div className={clsx(s.searchBar, className)}>
      <SearchBox />
      <Divider />
      <Selector
        label="Filters"
        marker={filtersCount}
        classNames={{
          button: s.filtersButton,
          container: s.filtersContainer,
          content: s.filtersContent,
          contentInner: s.filtersContentInner,
        }}
      >
        <LocationSelector
          selectorProps={{ classNames: selectorClassNames }}
          onUpdateAddress={setAddress}
        />
        <Divider />
        <CategorySelector
          selectorProps={{ classNames: selectorClassNames }}
          onUpdateCount={setCategoryCount}
        />
        <Divider />
        <MoreFiltersSelector
          selectorProps={{ classNames: selectorClassNames }}
          onUpdateCount={setMoreFiltersCount}
        />
      </Selector>
      <Button
        className={s.clearButton}
        onClick={() => {
          setSearchState({})
          track('SearchFilters:Clear:Click', null)
        }}
      >
        <span className={s.clearButtonText}>Clear</span>
      </Button>
    </div>
  )
}

function Divider() {
  return <i className={s.divider} aria-hidden />
}
