import clsx from 'clsx'
import React, { ReactNode } from 'react'

import s from './Pill.module.scss'

type PillProps = {
  children: ReactNode
  className?: string
  variant?:
    | 'outlineBlack'
    | 'outlinePeriwinkle'
    | 'solidBlack'
    | 'solidIce'
    | 'solidPeriwinkle'
    | 'solidPink'
}

export function Pill({
  children,
  className,
  variant = 'solidBlack',
}: PillProps) {
  return <li className={clsx(s.pill, className, s[variant])}>{children}</li>
}

type PillContainerProps = {
  children: ReactNode
  className?: string
}

export function PillContainer({ children, className }: PillContainerProps) {
  return <ul className={clsx(s.pillContainer, className)}>{children}</ul>
}
