import React from 'react'
import { SearchBox as AisSearchBox } from 'react-instantsearch-dom'

import s from './SearchBox.module.scss'

export function SearchBox() {
  return (
    <div className={s.container}>
      <AisSearchBox
        translations={{
          placeholder: 'Search Keyword…',
        }}
      />
    </div>
  )
}
