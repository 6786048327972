import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { SearchState, ConnectorSearchResults } from 'react-instantsearch-core'
import { createConnector } from 'react-instantsearch-dom'

import { useDebounce } from '../../../hooks'
import { Doc } from '../../../types/algolia'
import { useAnalytics } from '../../analytics/track'
import { Button } from '../../elements/Button'
import { Selector, SelectorTitle, SelectorProps } from '../../elements/Selector'
import { TextInput } from '../../elements/TextInput'
import { useSearchContext } from '../context'

import s from './LocationSelector.module.scss'

type BoundingBox = SearchState['boundingBox']

export type LocationSearchState = {
  address: string
}

type RefineArg = {
  boundingBox: BoundingBox
  location: LocationSearchState
}
type ExposedProps = {
  onUpdateAddress?: (count: string) => void
  selectorProps?: Partial<SelectorProps>
}

type ProvidedProps = ExposedProps & {
  locationSearchState: LocationSearchState
  searchResults: ConnectorSearchResults<Doc>
}

type ProvidedPropsWithRefine = ProvidedProps & {
  refine: (a: RefineArg) => void
}

const connectLocationSelector = createConnector<ProvidedProps, ExposedProps>({
  cleanUp(props, { location, ...nextSearchState }) {
    return nextSearchState
  },
  displayName: 'withLocationSelector',
  getProvidedProps(props, searchState, searchResults) {
    return {
      locationSearchState: searchState.location ?? { address: '' },
      searchResults,
    }
  },
  getSearchParameters(params) {
    return params
  },
  refine(props, searchState, { boundingBox, location }: RefineArg) {
    return {
      ...searchState,
      boundingBox,
      location,
    }
  },
})

export const LocationSelector = connectLocationSelector(
  ({
    locationSearchState,
    onUpdateAddress,
    refine,
    searchResults,
    selectorProps,
  }: ProvidedPropsWithRefine) => {
    const { googleSdk, searchState } = useSearchContext()
    const [value, setValue] = useState('')
    const [address, setAddress] = useState<string>(locationSearchState.address)
    const [boundingBox, setBoundingBox] = useState<BoundingBox>()
    const [loading, setLoading] = useState(false)
    const debouncedValue = useDebounce(value, 300)
    const aroundLatLng = searchResults?.results?.aroundLatLng

    const { track } = useAnalytics()

    useEffect(() => {
      if (!googleSdk || !debouncedValue) {
        setAddress('')
        setLoading(false)
        return
      }
      void (async () => {
        try {
          setLoading(true)
          const geocoder = new googleSdk.maps.Geocoder()
          const { northEast, southWest } = searchState?.boundingBox ?? {}
          const hasCoords = Boolean(southWest && northEast)

          let bounds: google.maps.LatLngBounds | null | undefined
          if (hasCoords) {
            bounds = new googleSdk.maps.LatLngBounds(southWest, northEast)
          } else if (aroundLatLng) {
            const [lat = 0, lng = 0] = aroundLatLng.split(',').map(Number)
            bounds = new googleSdk.maps.Circle({
              center: { lat, lng },
              radius: 10_000,
            }).getBounds()
          }

          const { results } = await geocoder.geocode({
            address: debouncedValue,
            bounds: bounds ?? undefined,
            region: !bounds ? 'au' : undefined,
          })
          const result = results[0]

          if (result) {
            setAddress(result.formatted_address)
            const { east, north, south, west } =
              result.geometry.viewport.toJSON()
            setBoundingBox({
              northEast: { lat: north, lng: east },
              southWest: { lat: south, lng: west },
            })
          }
        } catch (err) {}
        setLoading(false)
      })()
    }, [googleSdk, searchState?.boundingBox, debouncedValue, aroundLatLng])

    useEffect(() => {
      onUpdateAddress?.(locationSearchState.address)
    }, [locationSearchState.address, onUpdateAddress])

    return (
      <Selector
        label={locationSearchState.address || 'Location'}
        {...selectorProps}
      >
        <SelectorTitle>Search near location</SelectorTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            refine({ boundingBox, location: { address } })
            track('SearchFilters:Location:SelectLocation', {
              searchResult: address,
              searchTerm: value,
            })
          }}
          className={clsx(s.form, loading && s.loading)}
        >
          <div className={s.inputContainer}>
            <TextInput
              labelClassName={s.label}
              className={s.input}
              label={
                <>
                  Location
                  <span className={s.loadingSpinner}>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  </span>
                </>
              }
              placeholder="City, Postcode, Address"
              type="search"
              value={value}
              onChange={(e) => {
                setLoading(true)
                setValue(e.currentTarget.value)
              }}
            />
            {value && !loading && (
              <Button
                className={s.clearButton}
                type="reset"
                onClick={() => setValue('')}
              >
                <span className={s.clearButtonText}>Clear</span>
              </Button>
            )}
          </div>
          <div className={s.submitButtonContainer}>
            <Button
              className={s.submitButton}
              type="submit"
              disabled={Boolean(!address)}
            >
              {address || 'Start typing'}
            </Button>
          </div>
        </form>
      </Selector>
    )
  },
)
