import React, { ChangeEventHandler } from 'react'
import { createConnector } from 'react-instantsearch-dom'

import { FacetSliceName } from '../../../types/algolia'
import { useAnalytics } from '../../analytics/track'
import { Checkbox } from '../../elements/Checkbox'

export type SearchStateSlice = {
  [facet: string]: true
}

type Props = {
  additionalFacetMap?: { [facet: string]: string }
  className?: string
  facets: string[]
  refine: (searchStateSlice: SearchStateSlice) => void
  searchStateSlice: SearchStateSlice
}

export const createFacetSelector = (name: FacetSliceName) => {
  const connect = createConnector({
    cleanUp(props, { ...nextSearchState }) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete nextSearchState[name]
      return nextSearchState
    },
    displayName: `with_${name}Selector`,
    getProvidedProps(props, searchState) {
      return { searchStateSlice: searchState[name] ?? {} }
    },
    getSearchParameters(params, props: Props, searchState) {
      const { additionalFacetMap = {} } = props
      const searchStateSlice = searchState[name] ?? {}
      params = params.removeDisjunctiveFacet(name)
      params = params.addDisjunctiveFacet(name)

      for (const key of Object.keys(searchStateSlice ?? {})) {
        params = params.addDisjunctiveFacetRefinement(name, key)

        const additionalFacet = additionalFacetMap[key]
        if (additionalFacet) {
          params = params.addDisjunctiveFacetRefinement(name, additionalFacet)
        }
      }
      return params
    },
    refine(props, searchState, searchStateSlice: SearchStateSlice) {
      return {
        ...searchState,
        [name]: searchStateSlice,
      }
    },
  })

  return connect(({ className, facets, refine, searchStateSlice }: Props) => {
    const { track } = useAnalytics()

    const handleChange = (
      facet: string,
    ): ChangeEventHandler<HTMLInputElement> => {
      return (e) => {
        const selected = e.currentTarget.checked
        const newSearchState = toggleFacet(searchStateSlice, selected, facet)
        // We have to run the refine separately otherwise the state will not
        // update immediately after the checkbox is checked
        setTimeout(() => refine(newSearchState))

        const allSelected = Object.keys(newSearchState)
        const action = selected ? 'Select' : 'Unselect'
        track(`SearchFilters:${facetTitle(name)}:${action}`, {
          allSelected,
          numSelected: allSelected.length,
          target: facet,
        })
      }
    }

    return (
      <div className={className}>
        {facets.map((facet) => (
          <Checkbox
            key={facet}
            label={facet}
            checked={Boolean(searchStateSlice[facet])}
            onChange={handleChange(facet)}
          />
        ))}
      </div>
    )
  })
}

function facetTitle<F extends FacetSliceName>(facet: F) {
  return (facet.charAt(0).toUpperCase() + facet.slice(1)) as Capitalize<F>
}

function toggleFacet(
  searchStateSlice: SearchStateSlice | undefined,
  enabled: boolean,
  facet: string,
): SearchStateSlice {
  const newSlice = {
    ...searchStateSlice,
    [facet]: true as const,
  }

  if (!enabled) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newSlice[facet]
  }

  return newSlice
}
