import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, navigate } from 'gatsby'
import React, { useLayoutEffect, ReactElement } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { PageWrap, PageElementProps } from '../../layouts/shared'
import { SearchStateChangedHandler } from '../../types/algolia'
import { getSearchUri } from '../../utils/helpers'
import { safeJsonParse } from '../../utils/strings'

import { useSearchContext } from './context'
import { Search } from './Search'

import s from './SearchApp.module.scss'

const transitionDuration = parseInt(s.transitionDuration ?? '', 10)

export type SearchAppProps = {
  children: ReactElement
  pageProps: PageElementProps['props']
}

export function SearchApp({ children, pageProps }: SearchAppProps) {
  const { location } = pageProps
  const { searchState, setSearchState } = useSearchContext()
  const listingShowing = location.pathname !== '/search/'

  const handleSearchChange: SearchStateChangedHandler = (searchState) => {
    const searchUri = getSearchUri(searchState, location.search)
    void navigate(searchUri, {
      replace: true,
      state: { skipScroll: true },
    })
    setSearchState(searchState)
  }

  useLayoutEffect(() => {
    const searchStateFromQuery = safeJsonParse<SearchState>(
      new URLSearchParams(location.search).get('query') ?? '',
      {},
    )

    if (!searchState) {
      setSearchState(searchStateFromQuery)
    }
  }, [location.search, searchState, setSearchState])

  return (
    <div>
      <Search
        onSearchChange={handleSearchChange}
        listingShowing={listingShowing}
      />
      <TransitionGroup>
        {listingShowing ? (
          <CSSTransition
            timeout={transitionDuration}
            classNames={{
              enter: s.listingEnter,
              enterActive: s.listingEnterActive,
              exit: s.listingExit,
              exitActive: s.ListingExitActive,
            }}
            key={location.pathname}
          >
            <div className={s.listing}>
              <Link
                className={s.closeButton}
                to={getSearchUri(searchState)}
                replace
                title="Close"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Link>
              {children}
            </div>
          </CSSTransition>
        ) : (
          children
        )}
      </TransitionGroup>
    </div>
  )
}

export default function wrap({ element, props }: PageElementProps) {
  const content = props.location.pathname.match(/^\/(?:search|listing)\/.*/) ? (
    <SearchApp pageProps={props}>{element}</SearchApp>
  ) : (
    element
  )

  return <PageWrap pageProps={props}>{content}</PageWrap>
}
