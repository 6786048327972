import {
  useStaticQuery,
  graphql,
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
} from 'gatsby'
import React, { useRef, ReactNode } from 'react'
import { Helmet } from 'react-helmet'

import { HeadQuery, ListingTemplateQuery } from '../../types/graphql'
import { useAnalytics } from '../components/analytics/track'
import { listingTitle } from '../utils/strings'

export type PageElementProps =
  | WrapPageElementBrowserArgs<ListingTemplateQuery | undefined>
  | WrapPageElementNodeArgs<ListingTemplateQuery | undefined>

type PageWrapProps = {
  children?: ReactNode
  pageProps: PageElementProps['props']
}

export function PageWrap({ children, pageProps }: PageWrapProps) {
  const { data, path } = pageProps

  let title = 'Search'
  if (data) {
    const { business, name } = data.airtableLocations?.data ?? {}
    title = listingTitle(business?.[0]?.data?.name, name)
  }

  return (
    <>
      <Head title={title} path={path} />
      {children}
    </>
  )
}

type HeadProps = {
  description?: string
  path: string
  title?: string
}

function Head({ description, path, title = '' }: HeadProps) {
  const data = useStaticQuery<HeadQuery>(graphql`
    query Head {
      site {
        siteMetadata {
          title
          description
          author {
            name
            url
          }
        }
      }
    }
  `)

  const { analytics } = useAnalytics()
  const lastPathRef = useRef<string>()

  const { siteMetadata = {} } = data.site ?? {}

  return (
    <Helmet
      onChangeClientState={(newState) => {
        const newPath = newState.titleAttributes['data-pagePath']
        if (lastPathRef.current !== newPath) {
          void analytics.page()
          lastPathRef.current = newPath
        }

        if (process.env.GATSBY_PARENT_ORIGIN) {
          window.parent.postMessage(
            {
              payload: { title: newState.title },
              type: 'TITLE_UPDATE',
            },
            process.env.GATSBY_PARENT_ORIGIN,
          )
        }
      }}
    >
      <html lang="en" />
      {/* === Icons === */}
      <link rel="shortcut icon" href="/icons/favicon.ico" />
      {/* <meta name="theme-color" content="#e9f5ff" /> */}
      {/* <meta property="og:image" content="/images/social-image.png" /> */}
      {/* === SEO === */}
      <title data-pagePath={path}>
        {siteMetadata.title === title
          ? title
          : `${title ? title + ' | ' : ''}${siteMetadata.title}`}
      </title>
      <meta name="author" content={siteMetadata.author?.name} />
      <meta
        name="description"
        content={description ?? siteMetadata.description}
      />
      <meta charSet="UTF-8" />
      {/* === Device === */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  )
}
