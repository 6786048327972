import { AnalyticsBrowser } from '@segment/analytics-next'
import React, { useMemo, createContext, ReactNode } from 'react'

import { AnalyticsContextValue } from './types'

export const analyticsContext = createContext<
  AnalyticsContextValue | undefined
>(undefined)

type Props = {
  children?: ReactNode
}
export const AnalyticsProvider = ({ children }: Props) => {
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const analytics = useMemo(() => {
    return AnalyticsBrowser.load({
      cdnURL: process.env.GATSBY_SEGMENT_CDN_URL,
      writeKey: process.env.GATSBY_SEGMENT_WRITE_KEY ?? '',
    })
  }, [])

  const value = useMemo(() => ({ analytics }), [analytics])

  return (
    <analyticsContext.Provider value={value}>
      {children}
    </analyticsContext.Provider>
  )
}
