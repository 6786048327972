import clsx from 'clsx'
import React, { ReactNode, ButtonHTMLAttributes } from 'react'

import s from './Button.module.scss'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode
}

export function Button({ children, className, ...buttonProps }: Props) {
  return (
    <button {...buttonProps} className={clsx(s.button, className)}>
      {children}
    </button>
  )
}
