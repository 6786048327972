import { ReactElement } from 'react'
import { StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-dom'

type StateResultsProps = StateResultsProvided & {
  children: (props: StateResultsProvided) => ReactElement
}
export const StateResults = connectStateResults(
  ({ children, ...props }: StateResultsProps) => {
    return children(props)
  },
)
