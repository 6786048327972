import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useRef, useEffect } from 'react'
import { MarkerProps, CustomMarker } from 'react-instantsearch-dom-maps'

import { Doc, DirectoryHit } from '../../../types/algolia'
import { isOnlineOnly } from '../../../utils/hits'
import { useSearchContext } from '../context'

import s from './Marker.module.scss'

type Props = MarkerProps<Doc> & {
  hit: DirectoryHit
}

export function Marker({ hit }: Props) {
  const { hoveredHit, selectedHit, setScrollToSelected, setSelectedHit } =
    useSearchContext()
  const spanRef = useRef<HTMLSpanElement>(null)

  const isSelected = hit.objectID === selectedHit?.objectID
  const isHighlighted = isSelected || hit.objectID === hoveredHit?.objectID
  const hasSelected = Boolean(selectedHit || hoveredHit)

  useEffect(() => {
    const parentElem = spanRef.current?.parentElement
    if (isHighlighted) {
      parentElem?.classList.add(s.highlightedParent ?? '')
    } else {
      parentElem?.classList.remove(s.highlightedParent ?? '')
    }
  }, [isHighlighted])

  // Is only Online or Via distributors
  if (isOnlineOnly(hit)) {
    return null
  }

  return (
    <CustomMarker
      key={hit.objectID}
      hit={hit}
      onClick={() => {
        setScrollToSelected(true)
        setSelectedHit(hit)
      }}
    >
      <span
        ref={spanRef}
        className={clsx(
          s.marker,
          isHighlighted && s.isHighlighted,
          hasSelected && s.hasSelected,
        )}
      >
        <FontAwesomeIcon title={hit.businessName} icon={faMapMarker} />
      </span>
    </CustomMarker>
  )
}
